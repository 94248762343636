<template>
  <div class="card">
    <div
      v-b-toggle="`collapseResearcherRemark`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Additional information/remarks</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseResearcherRemark`"
      :visible="visible"
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body pt-0">
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['comments'] }}</label>
                <quill-editor v-model="item.researcher_remarks" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    item: { type: Object, required: true, default: () => {} },
    visible: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      labels: [],
    }
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.additional_information_remarks`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style>

</style>
